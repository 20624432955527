export const QUESTIONS = [
	{
		text: 'Co je to HTML?',
		type: 'checkbox',
		answers: [
			'HyperTwerking Mother-in-Law',
			'HyperText Makeup Language',
			'HyperText Markup Language',
			'Hyperspace Transport Magic Laundromat',
			'How To Make Love',
		],
		correct: ['HyperText Markup Language'],
	},
	{
		text: 'Jakým typem zápisu se nastavuje řez variabilních fontů?',
		type: 'radio',
		answers: [
			'font-weight: 900;',
			'variable-font-weight: 900;',
			'font-variation-settings: "wght" 400;',
			'font-variation-weight: 400;',
		],
		correct: ['font-weight: 900;', 'font-variation-settings: "wght" 400;'],
	},
	{
		text: 'Čeho je zkratka REST?',
		type: 'radio',
		answers: [
			'Repeated External Shared Token',
			'Representational State Transfer',
			'Request External Script Transfer',
			'Repeated State Transfer',
		],
		correct: ['Representational State Transfer'],
	},
	{
		text: 'Který z těchto projektů NEDĚLALI SUPERKODERS?',
		type: 'radio',
		answers: ['mujrozhlas.cz', 'pagespeed.cz', 'edalnice.cz', 'dronpro.cz'],
		correct: ['edalnice.cz'],
	},
	{
		text: 'Které z následujících JSOU validními jednotkami v CSS?',
		type: 'checkbox',
		answers: ['ch', 'turn', 'rem', 'vect'],
		correct: ['ch', 'turn', 'rem'],
	},
	{
		text: 'Jaká je návratová hodnota <code>querySelectorAll</code>?',
		type: 'radio',
		answers: ['array', 'object', 'undefined', 'string'],
		correct: ['object'],
	},
	{
		text: 'Co znamená zkratka CLS?',
		type: 'radio',
		answers: ['Coupé Light Sport', 'Cumulative Layout Shift', 'Common Language Specification', 'Clear screen'],
		correct: ['Cumulative Layout Shift'],
	},
	{
		text: 'Jakým způsobem vytvoříte DOM node v JavaScriptu?',
		type: 'radio',
		answers: ['window.createElement();', 'document.createElement();', 'createElement();', 'document.createNode();'],
		correct: ['document.createElement();'],
	},
	{
		text: 'Který z následujících není správnou hodnotou pro border-style?',
		type: 'radio',
		answers: ['dotted', 'inset', 'glazed', 'groove'],
		correct: ['glazed'],
	},
	{
		text: 'Umíš používat Firebug / Dev Tools / Inspektor webu?',
		mobileText: 'Umíš vyvolat orientationchange event?',
		type: 'hidden',
		answers: ['Ano', 'Ne'],
		correct: ['Ano'],
	},
	{
		text: 'Existují lepší kodéři než SUPERKODERS?',
		type: 'radio',
		answers: ['Ne', 'Nikdo', 'Není možno', 'Chuck Norris'],
		correct: ['Ne', 'Nikdo', 'Není možno', 'Chuck Norris'],
	},
];
