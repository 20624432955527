import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { QUESTIONS } from '../questions';
import { Answer } from './Answer';
import { Overlay } from './Overlay';
import { secsToTime } from './utils';

type Props = {
	onAnswerAll: () => void;
	answeringTime: number;
};

export const QuestionPage: FC<Props> = ({ onAnswerAll, answeringTime }) => {
	const [question, setQuestion] = useState(0);
	const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);
	const [disabledsSecs, setDisabledSecs] = useState(0);
	const [orientationChanged, setOrientationChanged] = useState(false);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const selected = event.target.value;
		if (QUESTIONS[question].type === 'radio') {
			setSelectedAnswers([selected]);
		} else {
			if (selectedAnswers.includes(selected)) {
				setSelectedAnswers(selectedAnswers.filter((answer) => answer != selected));
			} else {
				setSelectedAnswers([...selectedAnswers, selected]);
			}
		}
	};
	const countDown = useCallback((timeout: number) => {
		setDisabledSecs(timeout);
		if (timeout > 0) {
			setTimeout(() => {
				countDown(timeout - 1);
			}, 1000);
		}
	}, []);

	const evaluateAnswer = () => {
		if (!selectedAnswers.length) {
			return;
		}
		if (
			(QUESTIONS[question].type === 'radio' || QUESTIONS[question].correct.length === selectedAnswers.length) &&
			selectedAnswers.every((answer) => QUESTIONS[question].correct.includes(answer))
		) {
			if (question + 1 < QUESTIONS.length) {
				setQuestion(question + 1);
			} else {
				onAnswerAll();
			}
		} else {
			countDown(10);
		}
		setSelectedAnswers([]);
	};

	useEffect(() => {
		if (isMobile && QUESTIONS[question].type === 'hidden') {
			if ('onorientationchange' in window) {
				window.addEventListener('orientationchange', () => setOrientationChanged(true));
			} else if ('onresize' in window) {
				window.addEventListener('resize', () => setOrientationChanged(true));
			}
		}
		return () => {
			if ('onorientationchange' in window) {
				window.removeEventListener('orientationchange', () => setOrientationChanged(true));
			} else if ('onresize' in window) {
				window.removeEventListener('resize', () => setOrientationChanged(true));
			}
		};
	}, [question]);

	return (
		<div className="self-center md_mx-auto md_w-152 max-w-full">
			<h1>
				<span className="text-gray-36 font-bold text-sm mb-1 block">
					Otázka č. {question + 1} z {QUESTIONS.length}
				</span>

				<span
					className="mb-6 text-md font-bold block"
					dangerouslySetInnerHTML={{
						__html: (isMobile && QUESTIONS[question].mobileText) || QUESTIONS[question].text,
					}}
				/>
			</h1>
			<div className="answers mb-6">
				{QUESTIONS[question].answers.map((answer) => (
					<Answer
						answer={answer}
						type={
							QUESTIONS[question].type === 'hidden' && orientationChanged
								? 'radio'
								: QUESTIONS[question].type
						}
						handleChange={handleChange}
						selectedAnswers={selectedAnswers}
						key={answer}
					/>
				))}
			</div>
			<button onClick={evaluateAnswer} disabled={!selectedAnswers.length && !disabledsSecs}>
				Vyhodnotit
			</button>
			{disabledsSecs > 0 && <Overlay disabledsSecs={disabledsSecs} />}
			<p className="mb-12 mt-12 text-right">
				<span className="font-bold timer">{secsToTime(answeringTime)}</span>
			</p>
		</div>
	);
};
