import { FC } from 'react';

type Props = {};

export const PF2021: FC<Props> = () => {
	return (
		<span className="icon-svg icon-svg--pf2021">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				x="0"
				y="0"
				version="1.1"
				viewBox="0 0 595.3 280.6"
				xmlSpace="preserve"
			>
				<g id="spunt">
					<defs>
						<path
							id="SVGID_1_"
							d="M148.6 108.5L122.5 141l16.4 16.4 32.6-26.1c4.5-3.6 5.7-9.7 2.5-12.8L161.4 106c-1.2-1.2-2.8-1.8-4.6-1.8-2.9 0-6 1.5-8.2 4.3"
						/>
					</defs>
					<clipPath id="SVGID_2_">
						<use overflow="visible" xlinkHref="#SVGID_1_" />
					</clipPath>
					<linearGradient
						id="SVGID_3_"
						x1="0"
						x2="1"
						y1="281.13"
						y2="281.13"
						gradientTransform="matrix(53.2461 0 0 -53.2461 122.502 15099.865)"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0" stop-color="#a578b3" />
						<stop offset=".015" stop-color="#a578b3" />
						<stop offset=".125" stop-color="#a97cac" />
						<stop offset=".288" stop-color="#b38898" />
						<stop offset=".484" stop-color="#c49c77" />
						<stop offset=".706" stop-color="#dcb74a" />
						<stop offset=".946" stop-color="#fada10" />
						<stop offset=".981" stop-color="#ffdf07" />
						<stop offset="1" stop-color="#ffdf07" />
					</linearGradient>
					<path fill="url(#SVGID_3_)" d="M122.5 104.2h54.6v53.2h-54.6z" clip-path="url(#SVGID_2_)" />
				</g>
				<defs>
					<path
						id="SVGID_4_"
						d="M0 225.4v55.2h55.4c8.3-10.9 15.1-22.7 20.3-35.4l-38.6-2.6-1.7-37.6c-12.6 5.3-24.5 12.1-35.4 20.4"
					/>
				</defs>
				<clipPath id="SVGID_5_">
					<use overflow="visible" xlinkHref="#SVGID_4_" />
				</clipPath>
				<linearGradient
					id="SVGID_6_"
					x1="0"
					x2="1"
					y1="281.189"
					y2="281.189"
					gradientTransform="matrix(87.8524 0 0 -87.8524 -12.114 24945.988)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stop-color="#804192" />
					<stop offset=".995" stop-color="#82cac5" />
					<stop offset="1" stop-color="#82cac5" />
				</linearGradient>
				<path fill="url(#SVGID_6_)" d="M0 205h75.7v75.6H0z" clip-path="url(#SVGID_5_)" />
				<defs>
					<path
						id="SVGID_7_"
						d="M101.4 155.3l-3.9 9.1-11 11.6c-11.3 11.3-25 19.8-40.2 24.9l1.4 31.3 32.1 2.2c5.1-15.1 13.7-28.9 25-40.2l11-11.6 9.1-3.9 6.5-6.5-23.4-23.4-6.6 6.5z"
					/>
				</defs>
				<clipPath id="SVGID_8_">
					<use overflow="visible" xlinkHref="#SVGID_7_" />
				</clipPath>
				<linearGradient
					id="SVGID_9_"
					x1="0"
					x2="1"
					y1="281.134"
					y2="281.134"
					gradientTransform="matrix(85.028 0 0 -85.028 46.325 24095.873)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stop-color="#804192" />
					<stop offset=".995" stop-color="#82cac5" />
					<stop offset="1" stop-color="#82cac5" />
				</linearGradient>
				<path fill="url(#SVGID_9_)" d="M46.3 148.8h85v85.7h-85z" clip-path="url(#SVGID_8_)" />
				<defs>
					<path
						id="SVGID_10_"
						d="M298.1 186.9c-1.4-1.6-.8-4.7 1.4-6.7l.1-.1c2.2-2.1 5.4-3 8.9-2.7-.1 3.6-1.3 6.7-3.6 8.8l-.1.1c-1.2 1.1-2.6 1.7-4 1.7h-.1c-.6 0-1.8-.1-2.6-1.1m-12.2-43.5h-.7c-2.6.1-4.7 2.3-4.6 5v.1c.1 2.6 2.3 4.7 4.9 4.6 5.6-.2 11.9 3 16.5 8.3 1.7 1.9 3.1 4 4.1 6.2-5 .2-9.5 2-13 5.2l-.1.1c-6.1 5.6-7 14.8-2 20.5 2.5 2.8 6.1 4.4 10 4.3 3.7-.1 7.2-1.6 10.1-4.2l.1-.1c3.5-3.3 5.7-7.7 6.4-12.7 2 1.3 3.9 2.9 5.6 4.9 1.3 1.5 3.2 5 4.5 8.2 1 2.5 3.8 3.7 6.2 2.7 2.4-1 3.6-3.9 2.6-6.4-1.3-3.3-3.8-8.2-6.3-11-3.8-4.4-8.4-7.6-13.4-9.6-1.3-5.2-4-10.3-7.9-14.7-6.1-7.1-14.8-11.4-23-11.4"
					/>
				</defs>
				<clipPath id="SVGID_11_">
					<use overflow="visible" xlinkHref="#SVGID_10_" />
				</clipPath>
				<linearGradient
					id="SVGID_12_"
					x1="0"
					x2="1"
					y1="281.118"
					y2="281.118"
					gradientTransform="scale(55.8295 -55.8295) rotate(-87 -146.706 136.382)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stop-color="#804192" />
					<stop offset=".134" stop-color="#84468d" />
					<stop offset=".298" stop-color="#905580" />
					<stop offset=".477" stop-color="#a46e6b" />
					<stop offset=".667" stop-color="#c0904c" />
					<stop offset=".864" stop-color="#e3bc26" />
					<stop offset="1" stop-color="#ffdf07" />
				</linearGradient>
				<path fill="url(#SVGID_12_)" d="M337.4 140.4l3 57.3-59.7 3.1-3-57.2z" clip-path="url(#SVGID_11_)" />
				<defs>
					<path
						id="SVGID_13_"
						d="M188.4 99.4c-.7 0-1.4-.3-2-.8-1.1-1-1.7-2.5-1.8-4.3 1.8-.1 3.3.3 4.4 1.3 1.1 1 1.4 2.5.7 3.3-.3.4-.9.5-1.3.5.1 0 0 0 0 0m-4.1-16.1c-1.9 2.1-3.2 4.6-3.9 7.1-2.4.9-4.7 2.5-6.6 4.7-1.2 1.4-2.4 3.7-3.1 5.4-.5 1.2.1 2.6 1.3 3.1s2.6-.1 3.1-1.3c.6-1.6 1.6-3.3 2.2-4 .8-.9 1.8-1.7 2.8-2.4.4 2.5 1.5 4.6 3.2 6.2 1.4 1.3 3.2 2 5 2.1 1.9 0 3.7-.7 4.9-2.1 2.5-2.8 2-7.2-1-10-1.8-1.6-4-2.5-6.5-2.6.5-1.1 1.2-2.1 2.1-3 2.3-2.6 5.4-4.1 8.2-4 1.3 0 2.4-1 2.4-2.2v-.1c0-1.3-1-2.4-2.3-2.4h-.4c-4-.1-8.3 2-11.4 5.5"
					/>
				</defs>
				<clipPath id="SVGID_14_">
					<use overflow="visible" xlinkHref="#SVGID_13_" />
				</clipPath>
				<linearGradient
					id="SVGID_15_"
					x1="0"
					x2="1"
					y1="281.128"
					y2="281.128"
					gradientTransform="scale(-27.1781) rotate(-87 -153.023 142.732)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stop-color="#804192" />
					<stop offset=".995" stop-color="#82cac5" />
					<stop offset="1" stop-color="#82cac5" />
				</linearGradient>
				<path fill="url(#SVGID_15_)" d="M170.2 76.2l-1.4 27.9 29.6 1.6 1.4-27.9z" clip-path="url(#SVGID_14_)" />
				<defs>
					<path
						id="SVGID_16_"
						d="M203.6 104.3c-1.4.5-2.4 2-1.9 3.4 1 2.8 3.3 4.7 6.1 5.5 2.9.9 5.9.4 8.7-.4 2.7-.8 5.3-1.5 8.1-1.3 3.9.6 7.5 3.2 9.1 6.8.5 1.5 1.9 2.9 3.6 2.3 1.3-.5 2.5-2 1.9-3.4-1.9-5.1-6-8.9-11.2-10.5-2.6-.8-5.3-.9-8-.5-2.8.5-11.4 3.9-13.2 0-.5-1.1-1.5-2-2.7-2 0 0-.3 0-.5.1"
					/>
				</defs>
				<clipPath id="SVGID_17_">
					<use overflow="visible" xlinkHref="#SVGID_16_" />
				</clipPath>
				<linearGradient
					id="SVGID_18_"
					x1="0"
					x2="1"
					y1="281.298"
					y2="281.298"
					gradientTransform="scale(25.0532 -25.0532) rotate(-74 -184.978 132.898)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stop-color="#804192" />
					<stop offset=".995" stop-color="#82cac5" />
					<stop offset="1" stop-color="#82cac5" />
				</linearGradient>
				<path fill="url(#SVGID_18_)" d="M236.9 93.9l7.5 26-40.3 11.6-7.5-26z" clip-path="url(#SVGID_17_)" />
				<defs>
					<path
						id="SVGID_19_"
						d="M192.5 26.7c-2.2 2.9-2.5 6.5-1.4 10s3.6 6.2 6.3 8.5c2.6 2.3 5.1 4.3 6.9 7.3 2.1 4.3 1.9 9.7-.6 13.8-1.2 1.6-1.7 3.9.1 5.2 1.4 1 3.7 1.1 4.8-.4 3.8-5.4 4.9-12.1 3-18.5-.9-3.2-2.7-6-5-8.4-2.4-2.6-11.7-8.9-9-13.3.9-1.5 1.1-3.6-.4-4.8-.6-.5-1.5-.8-2.3-.8-.8.2-1.8.6-2.4 1.4"
					/>
				</defs>
				<clipPath id="SVGID_20_">
					<use overflow="visible" xlinkHref="#SVGID_19_" />
				</clipPath>
				<linearGradient
					id="SVGID_21_"
					x1="0"
					x2="1"
					y1="281.334"
					y2="281.334"
					gradientTransform="scale(-30.539 30.539) rotate(50 296.873 133.873)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stop-color="#804192" />
					<stop offset=".995" stop-color="#82cac5" />
					<stop offset="1" stop-color="#82cac5" />
				</linearGradient>
				<path fill="url(#SVGID_21_)" d="M236.8 45l-33 39.3-37-31.1 32.9-39.3z" clip-path="url(#SVGID_20_)" />
				<g>
					<defs>
						<path
							id="SVGID_22_"
							d="M280.1 43.6c-7.7 2.2-14.4 6.5-20.2 12-6.2 5.9-21.6 28.2-32.1 21.7-3.6-2.3-8.8-2.6-11.6 1-2.6 3.4-2.7 8.8 1 11.6 7.1 5.3 15.8 6.1 24.1 3.4 8.5-2.7 15-8.7 20.6-15.3 5.5-6.4 10.4-12.3 17.7-16.7 10.5-5 23.4-4.5 33.4 1.5 3.8 2.8 9.3 4.1 12.6-.2 2.5-3.3 2.7-8.9-1-11.6-8.8-6.3-19.2-9.5-29.8-9.5-4.8 0-9.8.7-14.7 2.1"
						/>
					</defs>
					<clipPath id="SVGID_23_">
						<use overflow="visible" xlinkHref="#SVGID_22_" />
					</clipPath>
					<linearGradient
						id="SVGID_24_"
						x1="0"
						x2="1"
						y1="281.318"
						y2="281.318"
						gradientTransform="scale(73.7596 -73.7596) rotate(-40 -385.657 135.813)"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0" stop-color="#804192" />
						<stop offset=".995" stop-color="#82cac5" />
						<stop offset="1" stop-color="#82cac5" />
					</linearGradient>
					<path
						fill="url(#SVGID_24_)"
						d="M261-15.1L355.2 64 281 152.5l-94.3-79.1z"
						clip-path="url(#SVGID_23_)"
					/>
				</g>
				<g>
					<defs>
						<path
							id="SVGID_25_"
							d="M516.7 140.4c-2.2.6-4.1 2.9-3.5 5.3 1.3 4.7 4.7 7.9 9.2 9.6 4.5 1.7 9.4 1.3 14 .4 4.5-.9 8.6-1.8 13.2-1.2 6.2 1.4 11.6 6 13.9 11.9.7 2.5 2.6 4.9 5.5 4.1 2.2-.6 4.2-2.9 3.4-5.3-2.5-8.3-8.7-14.9-16.7-18.1-4.1-1.6-8.4-2-12.7-1.6-4.6.4-18.8 4.9-21.1-1.5-.7-1.9-2.3-3.6-4.4-3.6-.2-.1-.5 0-.8 0"
						/>
					</defs>
					<clipPath id="SVGID_26_">
						<use overflow="visible" xlinkHref="#SVGID_25_" />
					</clipPath>
					<linearGradient
						id="SVGID_27_"
						x1="0"
						x2="1"
						y1="281.3"
						y2="281.3"
						gradientTransform="scale(40.2434 -40.2434) rotate(-78 -169.079 130.699)"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0" stop-color="#804192" />
						<stop offset=".995" stop-color="#82cac5" />
						<stop offset="1" stop-color="#82cac5" />
					</linearGradient>
					<path
						fill="url(#SVGID_27_)"
						d="M570.6 128l8.9 41.8-64.3 13.7-8.9-41.9z"
						clip-path="url(#SVGID_26_)"
					/>
				</g>
				<path
					id="Line4"
					d="M156 213.6l-10.9-31.1"
					fill="none"
					stroke="#fff"
					stroke-width="6"
					stroke-linecap="round"
				/>
				<path
					id="Line3"
					d="M186.5 178.6l-32-8.3"
					fill="none"
					stroke="#fff"
					stroke-width="6"
					stroke-linecap="round"
				/>
				<path
					id="Line2"
					d="M97.8 136.3l-32-8.3"
					fill="none"
					stroke="#fff"
					stroke-width="6"
					stroke-linecap="round"
				/>
				<path
					id="Line1"
					d="M108.6 120.7l-64.9-86"
					fill="none"
					stroke="#fff"
					stroke-width="6"
					stroke-linecap="round"
				/>

				<g>
					<defs>
						<path
							id="SVGID_28_"
							d="M115.5 87.1c-1.5 0-3.1-.7-4.4-1.9l-.1-.1c-2.5-2.3-3.9-5.6-4-9.6 3.9-.3 7.4.7 9.9 3l.1.1c2.4 2.2 3.1 5.6 1.5 7.4-.9 1-2.1 1.1-2.9 1.1h-.1M106.3 51c-4.2 4.7-7.2 10.3-8.7 16-5.5 2.1-10.6 5.7-14.9 10.4-2.8 3.1-5.5 8.4-6.9 12-1.1 2.7.2 5.8 2.9 6.9 2.7 1.1 5.8-.2 6.9-2.9 1.4-3.5 3.6-7.3 5-8.9 1.9-2.1 4-3.9 6.2-5.3.8 5.5 3.3 10.3 7.2 13.9l.1.1c3.2 2.9 7.2 4.5 11.2 4.6 4.3.1 8.3-1.6 11.1-4.7 5.5-6.2 4.5-16.2-2.3-22.4l-.1-.1c-3.9-3.6-8.9-5.5-14.5-5.7 1.2-2.4 2.7-4.7 4.6-6.8 5.1-5.7 12.2-9.2 18.4-9 2.9.1 5.3-2.2 5.4-5V44c.1-2.9-2.2-5.4-5.1-5.5h-.8c-9.1 0-18.7 4.6-25.7 12.5"
						/>
					</defs>
					<clipPath id="SVGID_29_">
						<use overflow="visible" xlinkHref="#SVGID_28_" />
					</clipPath>
					<linearGradient
						id="SVGID_30_"
						x1="0"
						x2="1"
						y1="281.128"
						y2="281.128"
						gradientTransform="scale(-60.886) rotate(-87 -149.332 141.192)"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0" stop-color="#804192" />
						<stop offset=".995" stop-color="#82cac5" />
						<stop offset="1" stop-color="#82cac5" />
					</linearGradient>
					<path
						fill="url(#SVGID_30_)"
						d="M74.8 35.2l-3.2 62.5 66.3 3.5 3.2-62.5z"
						clip-path="url(#SVGID_29_)"
					/>
				</g>
				<g>
					<defs>
						<path
							id="SVGID_31_"
							d="M186.7 148.9c0-2 1.6-3.6 3.6-3.6s3.6 1.6 3.6 3.6-1.6 3.6-3.6 3.6-3.6-1.6-3.6-3.6m-7.7 0c0 6.2 5.1 11.3 11.3 11.3 6.2 0 11.3-5.1 11.3-11.3 0-6.2-5.1-11.3-11.3-11.3-6.3 0-11.3 5.1-11.3 11.3"
						/>
					</defs>
					<clipPath id="SVGID_32_">
						<use overflow="visible" xlinkHref="#SVGID_31_" />
					</clipPath>
					<linearGradient
						id="SVGID_33_"
						x1="0"
						x2="1"
						y1="281.13"
						y2="281.13"
						gradientTransform="matrix(22.5583 0 0 -22.5583 178.96 6490.743)"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0" stop-color="#804192" />
						<stop offset=".995" stop-color="#82cac5" />
						<stop offset="1" stop-color="#82cac5" />
					</linearGradient>
					<path fill="url(#SVGID_33_)" d="M179 137.6h22.6v22.6H179z" clip-path="url(#SVGID_32_)" />
				</g>
				<g>
					<defs>
						<path
							id="SVGID_34_"
							d="M33.8 148.9c0-1.7 1.3-3 2.9-3 1.6 0 2.9 1.3 2.9 3s-1.3 3-2.9 3c-1.7 0-2.9-1.3-2.9-3m-6.2 0c0 5.3 4.1 9.5 9.1 9.5s9.1-4.3 9.1-9.5c0-5.3-4.1-9.5-9.1-9.5-5.1 0-9.1 4.3-9.1 9.5"
						/>
					</defs>
					<clipPath id="SVGID_35_">
						<use overflow="visible" xlinkHref="#SVGID_34_" />
					</clipPath>
					<linearGradient
						id="SVGID_36_"
						x1="0"
						x2="1"
						y1="280.63"
						y2="280.63"
						gradientTransform="scale(26.2926 -26.2926) rotate(46.5 334.129 138.52)"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0" stop-color="#804192" />
						<stop offset=".995" stop-color="#82cac5" />
						<stop offset="1" stop-color="#82cac5" />
					</linearGradient>
					<path
						fill="url(#SVGID_36_)"
						d="M18 149.4l18.1-19.1 19.1 18.1-18.1 19.1z"
						clip-path="url(#SVGID_35_)"
					/>
				</g>
				<g>
					<defs>
						<path
							id="SVGID_37_"
							d="M43.8 72.5c-.5.3-.7.9-.4 1.4l15 25.7-27.5-11.3c-.5-.2-1.1 0-1.3.6-.2.5 0 1.1.6 1.3l30.3 12.4c.4.2.9.1 1.2-.3.3-.3.3-.8.1-1.2L45.2 72.9c-.1-.1-.1-.2-.2-.3-.2-.2-.4-.3-.7-.3-.2.1-.4.1-.5.2"
						/>
					</defs>
					<clipPath id="SVGID_38_">
						<use overflow="visible" xlinkHref="#SVGID_37_" />
					</clipPath>
					<linearGradient
						id="SVGID_39_"
						x1="0"
						x2="1"
						y1="280.958"
						y2="280.958"
						gradientTransform="scale(-33.0951 33.0951) rotate(-41 -372.549 144.524)"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0" stop-color="#804192" />
						<stop offset=".995" stop-color="#82cac5" />
						<stop offset="1" stop-color="#82cac5" />
					</linearGradient>
					<path
						fill="url(#SVGID_39_)"
						d="M47.9 119L14.3 89.7l29.1-33.5L77 85.5z"
						clip-path="url(#SVGID_38_)"
					/>
				</g>
				<g>
					<defs>
						<path
							id="SVGID_40_"
							d="M216 142.1c.1 2.4 2 4.5 4.5 4.5 2.3 0 4.6-2.1 4.5-4.5-.1-2.4-2-4.5-4.5-4.5-2.4 0-4.6 2.1-4.5 4.5"
						/>
					</defs>
					<clipPath id="SVGID_41_">
						<use overflow="visible" xlinkHref="#SVGID_40_" />
					</clipPath>
					<linearGradient
						id="SVGID_42_"
						x1="0"
						x2="1"
						y1="281.13"
						y2="281.13"
						gradientTransform="matrix(8.9625 0 0 -8.9625 216 2661.739)"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0" stop-color="#804192" />
						<stop offset=".995" stop-color="#82cac5" />
						<stop offset="1" stop-color="#82cac5" />
					</linearGradient>
					<path fill="url(#SVGID_42_)" d="M215.9 137.6h9.2v9h-9.2z" clip-path="url(#SVGID_41_)" />
				</g>
				<g>
					<defs>
						<path
							id="SVGID_43_"
							d="M20.5 56.9c.1 2.4 2 4.5 4.5 4.5 2.3 0 4.6-2.1 4.5-4.5-.1-2.4-2-4.5-4.5-4.5-2.4 0-4.6 2.1-4.5 4.5"
						/>
					</defs>
					<clipPath id="SVGID_44_">
						<use overflow="visible" xlinkHref="#SVGID_43_" />
					</clipPath>
					<linearGradient
						id="SVGID_45_"
						x1="0"
						x2="1"
						y1="281.13"
						y2="281.13"
						gradientTransform="matrix(8.9625 0 0 -8.9625 20.51 2576.544)"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0" stop-color="#804192" />
						<stop offset=".995" stop-color="#82cac5" />
						<stop offset="1" stop-color="#82cac5" />
					</linearGradient>
					<path fill="url(#SVGID_45_)" d="M20.4 52.4h9.2v9h-9.2z" clip-path="url(#SVGID_44_)" />
				</g>
				<g>
					<defs>
						<path
							id="SVGID_46_"
							d="M61.8 155.4c.1 2.4 2 4.5 4.5 4.5 2.3 0 4.6-2.1 4.5-4.5-.1-2.4-2-4.5-4.5-4.5-2.3.1-4.6 2.1-4.5 4.5"
						/>
					</defs>
					<clipPath id="SVGID_47_">
						<use overflow="visible" xlinkHref="#SVGID_46_" />
					</clipPath>
					<linearGradient
						id="SVGID_48_"
						x1="0"
						x2="1"
						y1="281.13"
						y2="281.13"
						gradientTransform="matrix(8.9625 0 0 -8.9625 61.844 2675.07)"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0" stop-color="#804192" />
						<stop offset=".995" stop-color="#82cac5" />
						<stop offset="1" stop-color="#82cac5" />
					</linearGradient>
					<path fill="url(#SVGID_48_)" d="M61.7 151h9.2v9h-9.2z" clip-path="url(#SVGID_47_)" />
				</g>
				<g>
					<defs>
						<path
							id="SVGID_49_"
							d="M555.6 184c.1 2.4 2 4.5 4.5 4.5 2.3 0 4.6-2.1 4.5-4.5-.1-2.4-2-4.5-4.5-4.5-2.4 0-4.6 2.1-4.5 4.5"
						/>
					</defs>
					<clipPath id="SVGID_50_">
						<use overflow="visible" xlinkHref="#SVGID_49_" />
					</clipPath>
					<linearGradient
						id="SVGID_51_"
						x1="0"
						x2="1"
						y1="281.13"
						y2="281.13"
						gradientTransform="matrix(8.9625 0 0 -8.9625 555.59 2703.603)"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0" stop-color="#804192" />
						<stop offset=".995" stop-color="#82cac5" />
						<stop offset="1" stop-color="#82cac5" />
					</linearGradient>
					<path fill="url(#SVGID_51_)" d="M555.5 179.5h9.2v9h-9.2z" clip-path="url(#SVGID_50_)" />
				</g>
				<g>
					<defs>
						<path
							id="SVGID_52_"
							d="M297.6 117c.1 2.4 2 4.5 4.5 4.5 2.3 0 4.6-2.1 4.5-4.5-.1-2.4-2-4.5-4.5-4.5-2.3 0-4.6 2-4.5 4.5"
						/>
					</defs>
					<clipPath id="SVGID_53_">
						<use overflow="visible" xlinkHref="#SVGID_52_" />
					</clipPath>
					<linearGradient
						id="SVGID_54_"
						x1="0"
						x2="1"
						y1="281.13"
						y2="281.13"
						gradientTransform="matrix(8.9625 0 0 -8.9625 297.638 2636.575)"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0" stop-color="#804192" />
						<stop offset=".995" stop-color="#82cac5" />
						<stop offset="1" stop-color="#82cac5" />
					</linearGradient>
					<path fill="url(#SVGID_54_)" d="M297.5 112.5h9.2v9h-9.2z" clip-path="url(#SVGID_53_)" />
				</g>
				<g>
					<defs>
						<path
							id="SVGID_55_"
							d="M257.5 132.2c.1 2.4 2 4.5 4.5 4.5 2.3 0 4.6-2.1 4.5-4.5-.1-2.4-2-4.5-4.5-4.5-2.4 0-4.6 2-4.5 4.5"
						/>
					</defs>
					<clipPath id="SVGID_56_">
						<use overflow="visible" xlinkHref="#SVGID_55_" />
					</clipPath>
					<linearGradient
						id="SVGID_57_"
						x1="0"
						x2="1"
						y1="281.13"
						y2="281.13"
						gradientTransform="matrix(8.9625 0 0 -8.9625 257.494 2651.784)"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0" stop-color="#804192" />
						<stop offset=".995" stop-color="#82cac5" />
						<stop offset="1" stop-color="#82cac5" />
					</linearGradient>
					<path fill="url(#SVGID_57_)" d="M257.4 127.7h9.2v9h-9.2z" clip-path="url(#SVGID_56_)" />
				</g>
			</svg>
		</span>
	);
};
