import { FC } from 'react';

type Props = {
	onClick?: () => void;
};

export const LandingPage: FC<Props> = ({ onClick }) => (
	<>
		<div className="self-center">
			<hgroup className="mb-12 text-center">
				<h1 className="h1 mb-4">Vánoční Kvíz</h1>
				<h2 className="h2">Info a pravidla</h2>
			</hgroup>
			<p className="mb-4">
				🎅 K Ježíškovi ti nadělujeme kvíz ze znalostí frontendu! Pokud pohoříš, budeš mít alespoň co dělat o
				svátcích 😄
			</p>
			{/* <p className="mobile-warning mb-4 py-4">
				Jsi na telefonu nebo tabletu? Raději <strong>hned nažhav počítač</strong>. Neříkáme to jen tak,
				poděkovat nám můžeš potom, teď neztrácej čas!
			</p> */}
			<ul className="mb-12">
				<li className="mb-2">Čeká tě série (záludných) otázek.</li>
				<li className="mb-2">Při správné odpovědi se okamžitě objeví následující otázka.</li>
				<li className="mb-2">
					Odpovíš-li špatně, zůstaneš ve hře, ale na další možnost budeš muset chvilku počkat.
				</li>
			</ul>
			<p className="mb-4 text-center">Zlom vaz!</p>
			<p className="text-center">
				<button className="btn btn--lg" onClick={onClick}>
					Jsem ready!
				</button>
			</p>
		</div>
	</>
);
