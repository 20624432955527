import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { FinalPage } from './components/FinalPage';
import { LandingPage } from './components/LandingPage';
import { LogoSK } from './components/Logo';
import { QuestionPage } from './components/QuestionPage';

function App() {
	const [page, setPage] = useState(0);
	const [answeringTime, setAnsweringTime] = useState(0);
	const [countdownOn, setCountDownOn] = useState(false);
	const timer = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (countdownOn) {
			timer.current = setTimeout(() => {
				setAnsweringTime(answeringTime + 1);
			}, 1000);
		}
		return () => {
			if (timer.current) clearTimeout(timer.current);
			timer.current = null;
		};
	}, [answeringTime, countdownOn]);

	const handleNextPage = () => {
		setPage(page + 1);
		setCountDownOn(!countdownOn);
	};
	useEffect(() => {
		window.scrollTo({ top: 0 });
	}, [page]);
	const divEl = useRef<HTMLDivElement>(null);

	const classes = page === 2 ? 'App last-page' : 'App';

	return (
		<div className={classes}>
			<div className="grid grid-rows-layout min-h-screen relative z-10" ref={divEl}>
				<header
					role="banner"
					className="container mx-auto py-3 md:py-6 lg:py-9 pb-8 md:pb-12 lg:pb-16 lg:xxl_pb-24 text-center"
				>
					<div className="row-main">
						<h1 className="text-center">
							<LogoSK />
						</h1>
					</div>
				</header>
				<main role="main" id="main" className="container mx-auto flex pb-8 md:pb-12 lg:pb-16 xxl_pb-24">
					<div className="content mx-auto max-w-4xl flex-1 flex justify-center">
						{page === 0 ? (
							<LandingPage onClick={handleNextPage} />
						) : page === 1 ? (
							<QuestionPage onAnswerAll={handleNextPage} answeringTime={answeringTime} />
						) : (
							<FinalPage answeringTime={answeringTime} />
						)}
					</div>
				</main>
				<footer
					role="contentinfo"
					className="container mx-auto py-3 md:py-6 lg:py-9 text-center pt-4 xxl_pt-24"
				>
					{page === 2 ? (
						<p className="text-center mb-5">
							<a className="btn btn--sm" href="https://superkoders.cz/jobs">
								Jsi SUPER!
							</a>
						</p>
					) : null}
					<p className="center">&copy; 2020 SUPERKODERS</p>
				</footer>
			</div>
		</div>
	);
}

export default App;
