import React, { FC } from 'react';
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share';
import { PF2021 } from './pf2021';
import { secsToTime } from './utils';

type Props = { answeringTime: number };
const SHARE_URL = 'https://pf2021.superkoders.rocks/';
const HASHTAG = 'prejemesuperrok2021';

export const FinalPage: FC<Props> = ({ answeringTime }) => {
	return (
		<div className="self-center mx-auto text-center last-page">
			<h1 className="h1 mb-12">PF 2021</h1>
			<p className="h2 mb-12">Přejeme super rok 2021!</p>
			<p className="mb-12">
				Odpověděl jsi na všechny otázky v čase <br />
				<span className="font-bold timer">{secsToTime(answeringTime)}</span>
			</p>
			<p className="mb-6">
				Pochlub se s hashtagem <span className="font-bold">#{HASHTAG} </span>
			</p>
			<div>
				<FacebookShareButton
					url={SHARE_URL}
					hashtag={`#${HASHTAG}`}
					quote={`Vánoční kvíz od SUPERKODERS jsem udělal v čase ${secsToTime(
						answeringTime
					)}. Zvládneš to rychleji?`}
				>
					<FacebookIcon size={42} />
				</FacebookShareButton>
				<TwitterShareButton
					url={SHARE_URL}
					hashtags={[HASHTAG]}
					title={`Vánoční kvíz od @SUPERKODERS jsem udělal v čase ${secsToTime(
						answeringTime
					)}. Zvládneš to rychleji?`}
				>
					<TwitterIcon size={42} />
				</TwitterShareButton>
			</div>
			<PF2021 />
		</div>
	);
};
